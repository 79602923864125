.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* width: 100vh; */
}

.logo-layout {
    display: flex;
}

.logo {
    height: 100px;
    width: 300px;
    background-image: url("./images/ddebt_logo.png");
    background-size: 100%;
    background-repeat: no-repeat;
    margin-right: 30px;
    /* margin: 20px 0px 0px 100px; */
}

.logo-small {
    /* height: 90px; */
    /* width: 74px; */
    height: 60px;
    width: 140px;
    background-image: url("./images/ddebt_logo.png");
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 35px;
    margin-right: 90px;
    margin-left: 0px;
}

.logo-small-gsb {
    /* height: 90px; */
    /* width: 74px; */
    height: 60px;
    width: 140px;
    background-image: url("./images/gsb_logo.png");
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 35px;
    margin-right: 40px;
    margin-left: 90px;
}

.godji {
    height: 70px;
    width: 70px;
    background-image: url("./images/godji.png");
    background-size: 100%;
    background-repeat: no-repeat;
}

.header {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 0;
}

.header-gsb-pink {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 0;
    color: #ff058e;
}

.detail {
    text-align: left;
}

.title {
    font-weight: 500;
    font-size: 18px;
}

.button {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background: #ff058e;
    border: 1px solid #c9207d;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgb(0 0 0 / 4%);
    border-radius: 2px;
    color: white;
    font-size: 18px;
    font-weight: 500;
}

.footer {
    margin-top: 5px;
    opacity: 90%;
    font-size: 15px;
    text-align: center;
    /* padding-bottom: 20px; */
    padding-top: 30px;
}

.form-layout {
    display: flex;
}

.form {
    display: grid;
    padding-left: 40px;
    padding-right: 40px;
    row-gap: 15px;
    width: 500px;
    text-align: left;
}

.eyeslash {
    background-image: url("./images/EyeInvisible.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 30px;
    width: 30px;
    position: relative;
    float: right;
    margin-right: 15px;
    margin-top: -36px;
    cursor: pointer;
}

.eye {
    background-image: url("./images/Eyevisible.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 30px;
    width: 30px;
    position: relative;
    float: right;
    margin-right: 15px;
    margin-top: -33px;
    cursor: pointer;
}

.input {
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    font-size: 18px;
    letter-spacing: -0.17px;
    line-height: 30px;
    font-family: "Roboto", sans-serif;
    padding-left: 10px;
    background-color: #F2F4F8;
    border: 0px;
    font-weight: 400;
    margin-top: 5px;
    border-color: none;
}

input[type="checkbox"] {
    filter: hue-rotate(340deg);
}

input[type="text"], input[type="password"], input[type="email"] {
    outline: none;
}

.login-bg {
    background-image: url("./images/ddebt_login_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: bottom; */
    background-attachment: fixed;
}

@media only screen and (max-width: 560px) {
    .card-login .ant-card-body {
        width: 350px;
        /* height: 550px; */
        height: 480px;
        padding: 0px;
    }
    .logo-small {
        /* margin-left: 140px; */
        margin-left: 110px;
    }
    .godji {
        left: 260px !important;
    }
}
